<template>

  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :style="{width: modalWidth}">

          <div class="modal-header">
            <slot name="header" v-if="hasHeaderSlot">
            </slot>
            <button ref="focusMe" type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal()">
              <span class="material-icons-outlined" aria-hidden="true">
                cancel
              </span>
            </button>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer" v-if="hasFooterSlot">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>
<script>

  export default {
    name: 'TheModal',
    components: {
    },
    props: {
      isBig: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        loader: null,
      }
    },
    computed: {
      modalWidth: function() {
        return this.isBig==true?'380px':'300px';
      },
      hasFooterSlot () {
        return !!this.$slots['footer'];
      },
      hasHeaderSlot () {
        return !!this.$slots['header'];
      }
    },
    methods: {
      closeModal(){
        this.$emit("closeModal");
      },
    },              
    mounted() {
      setTimeout(() => {
        this.$refs.focusMe.focus();
      }, 500);
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;

    @media (max-width: 786px){ 
      outline: 0;
      position: relative;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      max-height: 550px;
      overflow: scroll;
    }

    @media (max-width: 400px){ 
      width: 300px!important;
      padding: 20px 20px;
    }

  }

  .modal-header{
    color:$primary;


    @media (max-width: 500px) {
      line-height: 1;
    }

  }

  .modal-header button{
    background-color: transparent;
    color: grey;
  }

  .modal-body{
    padding: 5px 1em;
  }

  .modal-header{
    border-bottom: none
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-default-button {
    float: right;
  }

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-footer{
  border-top: none;
  justify-content: center;
}

body[dir="rtl"] {
  .close {
    margin: 0;
    padding: 0;
  }
}
</style>
